<template>
<div>
      <div>
            <h4 class="text-center">Betala med Swish genom QR på samma enhet</h4>
            <p>Om du använder din mobil för både swish och hemsidan kan du enkelt betala med QR-koden genom följande steg.</p>
            <ol>
                  <li>
                        Ta en skärmbild med mobilen av startsidan där du ser QR-koden.
                  </li>
                  <li>
                        Öppna swish-appen och klicka på "swisha". <span class="text-primary font-weight-bold">Se bild 1</span>
                  </li>
                  <li>
                        Längst upp i appen ser du en ikon för att skanna QR-koder. Klicka på den. <span class="text-primary font-weight-bold">Se bild 2</span>
                  </li>
                  <li>Klicka på ikonen för att bläddra bland dina bilder. Bläddra fram din nyss tagna skärmbild. <span class="text-primary font-weight-bold">Se bild 3</span>
                  </li>
                  <li>
                        Signera betalningen. Klart!
                  </li>
            </ol>
            <b-row no-gutters>
                  <b-col class="text-center">
                        <span class="text-primary font-weight-bold">Bild 1</span>
                        <b-img class="w-100" :src="image('001.jpg')"></b-img>
                  </b-col>
                  <b-col class="text-center">
                        <span class="text-primary font-weight-bold">Bild 2</span>
                        <b-img class="w-100" :src="image('002.jpg')"></b-img>
                  </b-col>
                  <b-col class="text-center">
                        <span class="text-primary font-weight-bold">Bild 3</span>
                        <b-img class="w-100" :src="image('003.jpg')"></b-img>
                  </b-col>
            </b-row>
      </div>
      <div>
            <h4 class="text-center">Betala med Swish genom QR på annan enhet</h4>
            <p>Om du använder din mobil för swish men ex loggar in på tipset genom en dator, annan telefon eller platta gör du enligt följande.</p>
            <ol>
                  <li>
                        Logga in på tipset på den enhet du använder och visa startsidan så att swish-QR-koden syns på skärmen.
                  </li>
                  <li>
                        Öppna swish-appen på din telefon med Swish och klicka på "Skanna QR". <span class="text-primary font-weight-bold">Se bild 4</span>
                  </li>
                  <li>
                        Rikta telefonens kamera mot QR-koden och passa in den i rutan. <span class="text-primary font-weight-bold">Se bild 5</span>
                  </li>
                  <li>
                        Signera betalningen. Klart!
                  </li>
            </ol>
            <b-row no-gutters>
                  <b-col class="text-center">
                        <span class="text-primary font-weight-bold">Bild 4</span>
                        <b-img class="w-100" :src="image('020.jpg')"></b-img>
                  </b-col>
                  <b-col class="text-center">
                        <span class="text-primary font-weight-bold">Bild 5</span>
                        <b-img class="w-100" :src="image('021.jpg')"></b-img>
                  </b-col>
            </b-row>
      </div>
</div>
</template>

<script>
export default {
      name: 'SwishInstructions',
      methods: {
            image(path) {
                  return this.$images.swishimage(path);
            }
      }
};
</script>
